import {
    Box,
    Button,
    Heading,
    Img,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { FiSend } from 'react-icons/fi'
  import { HiPlay } from 'react-icons/hi'
  import {Link} from "react-router-dom";
  
  export function Apropos() {
    return (
      <Box as="section" pb="10">
        <Box maxW={{ base: 'xl', md: 'full' }} mx="auto" px={{ base: '6', md: '8' }}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '3rem', lg: '2rem' }}
            mt="8"
            align={{ lg: 'center' }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: '55%' }}>
              <Heading
                as="h1"
                size={{base: "md", md: "lg"}}
                color={mode("brand.jaune.900", "brand.jaune.900")}
                mt="8"
                fontWeight="extrabold"
                letterSpacing="tight"
                textAlign='center'
              >
                Plateforme Zoa
              </Heading>
              <Text color={mode('white', 'white')} mt="4" fontSize={{base: "md", md: "xl"}} fontWeight="medium">
              {/* Cette plateforme digitale vise à faciliter la mise en relation des organisations de la société civile et des partenaires techniques et financiers et à recenser les opportunités de financements pour les organisations de la société civile au Sahel.  */}
              L'objectif de cette plateforme digitale est de renforcer la coopération entre les acteurs de la société civile et les bailleurs de fonds, afin de promouvoir le développement durable et l'autonomisation des populations du Sahel. Grâce à cette plateforme, les organisations de la société civile pourront accéder plus facilement à des financements pour leurs projets et initiatives, et les partenaires techniques et financiers auront un accès simplifié à des partenaires locaux.

              </Text>
              {/* <Stack direction={{ base: 'column', md: 'row' }} spacing="4" mt="8">
                <Link to="/registration">
                <Button size="lg" minW="210px" colorScheme="teal"  leftIcon={<FiSend />}
                  textTransform="uppercase"
                  bg="brand.jaune.900" color={"white"}>J'inscris mon organisation
                </Button>
                </Link>
          
              </Stack> */}
            </Box>
            <Box pos="relative" w={{ base: 'full', lg: '40%' }} h={{ base: 'auto', lg: '400px' }}>
              <Img
                w="full"
                pos="relative"
                zIndex="1"
                h={{ lg: '100%' }}
                objectFit="cover"
                src="https://images.unsplash.com/photo-1620608929452-ccc05381b0f7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Screening talent"
              />
              <Box
                pos="absolute"
                w="100%"
                h="100%"
                top="-6"
                left="-4"
                bgGradient='linear(to-tl,brand.jaune.900, brand.bleu.900)'
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    )
  }