import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import LogoSA from '../../Media/images/logo.png'
import RegistrationForm from './RegistrationForm'
import { Routes, Route, useParams } from 'react-router-dom';

const Registration = () => {
    const { token } = useParams()
    // React.useEffect(() => {
    //     console.log("token", token, mode)
    // }, [token])

    return <Flex
        minH={{
            base: 'auto',
            md: '100vh',
        }}
        // bgGradient={useBreakpointValue({
        //     md: mode(
        //         'linear(to-r, brand.primary.100 50%, white 50%)',
        //         'linear(to-r, brand.primary.100 50%, gray.900 50%)',
        //     ),
        // })}
        bgGradient={useBreakpointValue({
            md: mode(
                'linear(to-r, brand.primary.100 50%, white 50%)',
                'linear(to-r,  brand.secondary 50%, brand.primary.100 50%)',
            ),
        })}
    >
        <Flex maxW="8xl" mx="auto" width="full">
            <Box
                flex="1"
                display={{
                    base: 'none',
                    md: 'block',
                }}
            >
                <DarkMode>
                    <Flex
                        direction="column"
                        px={{
                            base: '4',
                            md: '8',
                        }}
                        height="full"
                        color="on-accent"
                    >
                        <Flex align="center" h="24">
                            {/* <LogoSA/> */}
                        </Flex>
                        <Flex flex="1" align="center" color="white">
                            <Stack spacing="8">
                                <Stack spacing="6">
                                    <Heading
                                        size={useBreakpointValue({
                                            md: 'lg',
                                            xl: 'xl',
                                        })}
                                        color="white"
                                    >
                                       Plateforme Zoa
                                    </Heading>
                                    <Text fontSize="lg" maxW="md" fontWeight="medium">
                                    Cette plateforme digitale vise à faciliter la mise en relation des organisations de la société civile et des partenaires techniques et financiers et à recenser les opportunités de financements pour les organisations de la société civile au Sahel. 
                                    </Text>
                                </Stack>
                                <HStack spacing="4">
                                    <AvatarGroup
                                        size="md"
                                        max={useBreakpointValue({
                                            base: 2,
                                            lg: 5,
                                        })}
                                        borderColor="on-accent"
                                    >
                                        <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                                        <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                                        <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                                        <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
                                        <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                                    </AvatarGroup>
                                    <Text fontWeight="medium">Joindre 100+ Associations</Text>
                                </HStack>
                            </Stack>
                        </Flex>
                        <Flex align="center" h="24">
                            <Text color="white" fontSize="sm">
                                © 2024 ZOA PLATFORM. All rights reserved.
                            </Text>
                        </Flex>
                    </Flex>
                </DarkMode>
            </Box>
            <Center flex="1" >
                <RegistrationForm
                    token={token}
                // px={{
                //     base: '4',
                //     md: '8',
                // }}
                // py={{
                //     base: '12',
                //     md: '48',
                // }}
                // minW="2xl"
                />
            </Center>
        </Flex>
    </Flex>
}
export default Registration