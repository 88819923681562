import { Box, Link,Flex, Stack, Button, IconButton, Center, Heading, LightMode, SimpleGrid, Text, Spacer } from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'

export const Banner = () => {
  return (
    <Box
      as="section"
      bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '560px', md: '640px' }}
      bgImage="url(https://images.unsplash.com/photo-1573164713619-24c711fe7878?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1500&q=80)"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '8xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" h="full">
          <Heading  size={{base: "md", md: "lg"}} fontWeight="extrabold"
          // bgClip='text'
          // bgGradient='linear(to-r,brand.jaune.900, brand.primary.100)'
          color={"whiteAlpha.700"}
           >
          Plateforme des organisations de la société civile au Sahel  
          </Heading>

        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.400"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
        <Flex w="100%" justifyContent="space-between">
            <Box textAlign="center" color="white">
              <Text>Pays</Text>
              <Text fontSize="3xl">5</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Organisations de la societé civile</Text>
              <Text fontSize="3xl">89</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Bailleurs de fonds</Text>
              <Text fontSize="3xl">45</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Financement</Text>
              <Text fontSize="3xl">$5k - $12K</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}