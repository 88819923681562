import { useState, useEffect, useContext, useMemo } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  FormErrorMessage,
  PinInput,
  PinInputField,
  HStack,
  Spacer,
  Stack,
  Center,
  Text,
  Link,
  Radio,
  RadioGroup,
  Divider,
  IconButton
} from '@chakra-ui/react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from '@chakra-ui/react'
import { GlobalContext } from '../Context';
import { LogoSAVert } from '../Header/LogoSA';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { FiPlus } from 'react-icons/fi';
import { UilEllipsisH } from '@iconscout/react-unicons'

const RegistrationForm = ({
  token,
  ...props
}) => {
  const { login, retrieve, service } = useContext(GlobalContext)
  const toast = useToast()
  const [isLogin, setIsLogin] = useState(false)
  let navigate = useNavigate();
  const schemaReset = yup.object().shape({
    mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
    // motpasse: yup.string().min(8, "Minimum 8 caractere").required("Le mot de passe est obligatoire"),
    // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const schemaRegistration = yup.object().shape({
    mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
    // role_id: yup.string().required("Le profil est obligatoire"),
    // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const schemaToken = yup.object().shape({
    // mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
    motpasse: yup.string().min(8, "Minimum 8 caractere").required("Le mot de passe est obligatoire"),
    confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const onSubmit = async (data) => {
    if (['registration', 'reset'].includes(token)) {
      service.create("", "/tokens/reset", {
        mail: data?.mail,
        mode: token === 'registration' ? "creation": "reset"
      }, (resp => {

        toast({
          title: token === 'registration' ? 'Validation mail!' : 'Réinitialisation du mot de passe',
          description: ` Un mail de réinitialisation de votre mot de passe vient d'être envoyé à l'adresse ${data?.mail}.`,
          status: 'success',
          duration: 9000,
          position: "top-right",
          isClosable: true,
        })
        navigate('/')
      }), err => {
        console.log(err)
      })
    }
    else {
      service.update("", "/tokens/reset", '', {
        token: token,
        motpasse: data?.motpasse
      }, (resp => {
        const ok = login({
          mail: resp.data?.mail,
          motpasse: data?.motpasse
        })
        if (ok) {
          setIsLogin(!isLogin)
          navigate('/')

        }

      }), err => {
        console.log(err)
      })
    }
    // const resp = login(data)
    // if (resp) {
    //   setIsLogin(!isLogin)
    //   navigate('/')

    // }


  }
  const [selectedSchema, setSelectedSchema] = useState(schemaReset);
  const { register, handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(selectedSchema)
  });
  const liste = [{
    key: "RO-002",
    value: "OSC"
  }, {
    key: "RO-003",
    value: "ASH"
  },

  ]
  useEffect(() => {
    if (isLogin) {
      retrieve()
    }

  }, [isLogin])
  useEffect(() => {

    if (token === "reset") {

      setSelectedSchema(schemaReset)
    } else if (token === "registration") {
      setSelectedSchema(schemaRegistration)
    } else {
      setSelectedSchema(schemaToken)
    }

  }, [token])


  return (

    <Stack

      spacing="2" {...props} w={{ base: "full", md: 'xl' }} as="section"
      borderColor="brand.primary.100"
      shadow='lg'
      // borderRadius={"lg"}
      // px={5}
      borderBottomWidth={4}
      borderBottomColor={"brand.primary.100"}

    >
      <Center>


        <LogoSAVert
          title="Plateforme"
        />

      </Center>
      <Stack spacing="5" fontSize={"2xl"} px={10}>
        <form
        >

          {['registration', 'reset'].includes(token) &&
            <>
              <FormControl mt="2%" isInvalid={errors?.mail} size='lg' fontSize={"lg"}>
                <FormLabel htmlFor="email" fontWeight={'normal'}>
                  Email*
                </FormLabel>
                <Input id="email" type="email" {...register("mail")} name="mail" placeholder="Entrer votre email" />
                <FormHelperText>Veuillez fournir une adresse email valide.</FormHelperText>
                <FormErrorMessage>
                  {errors?.mail && errors?.mail?.message}
                </FormErrorMessage>
              </FormControl>
              {/* {"registration" === token && <FormControl borderTopColor={"brand.primary.100"} mt={5} isInvalid={errors["role_id"]} size={"lg"} >
                <FormLabel htmlFor={"role_id"} fontWeight={'normal'} textColor="brand.primary.100">
                  Profil
                </FormLabel>

                <Controller
                  control={control}
                  name={"role_id"}
                  // defaultValue={defaultValue}
                  render={({ field: { onChange, value, ref } }) => (
                    <RadioGroup
                      // placeholder={placeholder}
                      onChange={onChange}
                      value={value}

                    >
                      <SimpleGrid spacing={6}>
                        {liste.map((item, index) => {
                          return (
                            <Radio id={index} value={item.key} colorScheme='green'>
                              <Box >{item.value}</Box>
                            </Radio>
                          )
                        })
                        }
                      </SimpleGrid>
                    </RadioGroup>
                  )}
                />

                <FormErrorMessage>
                  {errors["role_id"]?.message}
                </FormErrorMessage>
                <FormHelperText></FormHelperText>
              </FormControl>} */}
            </>
          }

          {!['registration', 'reset'].includes(token) &&
            <> <FormControl mt="2%" isInvalid={errors?.motpasse} size='lg'>
              <FormLabel htmlFor="password" fontWeight={'normal'}>
                Password*
              </FormLabel>
              <Input id="motpasse" type="password" {...register("motpasse")} name="motpasse" placeholder="Enter the password" />
              <FormErrorMessage>
                {errors?.motpasse && errors?.motpasse?.message}
              </FormErrorMessage>
            </FormControl>
              <FormControl mt="2%" isInvalid={errors?.confirm_password} size='lg'>
                <FormLabel htmlFor="password" fontWeight={'normal'}>
                  Confirmation Mot de passe*
                </FormLabel>
                <Input id="confirm_password" type="password" {...register("confirm_password")} name="confirm_password" placeholder="Enter the password" />
                <FormErrorMessage>
                  {errors?.confirm_password && errors?.confirm_password?.message}
                </FormErrorMessage>
              </FormControl>
            </>
          }

          <ButtonGroup mt="5%" w="100%">
            <Flex w="100%" justifyContent="space-between">
              <Spacer />
              {<Button
                w="full"
                color={"whiteAlpha.900"}
                bgGradient="linear(to-l,brand.secondary, brand.primary.100)"
                variant="solid"
                onClick={handleSubmit(onSubmit)}
                size={"lg"}
              >
                {token==="reset" ? "Réinitialisation" : token === 'registration'? "Création de votre compte" : "Valider le mot de passe"}
              </Button>

              }
              <Box></Box>
            </Flex>
          </ButtonGroup>

        </form>
      </Stack>
      <HStack>
        <Divider />
        <IconButton variant="tertiary" icon={<UilEllipsisH />} aria-label="Add" />
        <Divider />
      </HStack>
      <Box textAlign={"center"} pb={5}>

        <Button as={Link} href='/login'
          w="full"
          bgColor={"whiteAlpha.900"}
          color="brand.primary.100"
          variant="solid"
        ><Text fontSize="md" color={'brand.primary.100'}>Se connecter </Text></Button>
      </Box>
    </Stack>
  )
}

export default RegistrationForm