import {
    Avatar,
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    Stack,
    Text,
    Divider,
    useBreakpointValue,
    useColorModeValue,
    VStack,
    Link,
    Center
} from '@chakra-ui/react'
import * as React from 'react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { FiSend } from 'react-icons/fi'
import { members } from './data'

import { UilFacebook, UilGlobe, UilTwitter, UilCalling, UilMap, UilLinkedin, UilAt } from '@iconscout/react-unicons'
import { UserInfo } from '../../Organisation/Projet/ProjetCard/UserInfo'
import Parser from 'html-react-parser'


export const Description = ({
    organisations
}) => (
    <Box bg="bg-surface"

    >
        <Container boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderTopWidth="4px"
            // borderTopWidth="4px"
            borderColor="brand.jaune.900"
            minW={{
                base: '100%',
                md: '80%',
            }}
            p={{ base: '5', md: '5' }}>
            <HStack pb={5}>
                <Divider />
                <Button flexShrink={0} variant="secondary" size={{ base: "sm", md: "md" }}>
                    <Text fontSize={{ base: "sm", md: "md" }} fontWeight="medium" color="brand.jaune.900">
                        Organisations de la société civile enregistrées
                    </Text>
                </Button>
                <Divider />
            </HStack>
            <Stack spacing={{ base: '12', xl: '24' }} direction={{ base: 'column', lg: 'row' }}>
                <Stack spacing="10">
                    <Stack spacing="3" maxW="sm" width="full">
                        <Stack spacing={{ base: '4', md: '5' }} align="center">
                            {/* <Heading size={useBreakpointValue({ base: 'sm', md: 'md' })}>Rejoindre les organisations de la sociétés civiles</Heading> */}
                            <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted" maxW="3xl">
                                Catalogue des organisations de la sociétés civiles actives dans les pays du Sahel
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack spacing="3" direction={{ base: 'column', md: 'row' }}>
                        <Link href="/association">
                            <Button bg="brand.bleu.900" color={"white"} size={{ base: "sm", md: "md" }}
                                w="full"
                            >
                                Catalogue des OSC
                            </Button>
                        </Link>
                        {/* <Button color={"white"} size="lg"> */}
                        <Link to="/registration">
                            <Button
                                size={{ base: "sm", md: "md" }}
                                w="full"
                                leftIcon={<FiSend />}
                                textTransform="uppercase"
                                bg="brand.jaune.900"
                                color={"white"}>J'inscris mon organisation</Button>
                        </Link>
                        {/* </Button> */}
                    </Stack>
                </Stack>
                <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    columnGap="8"
                    rowGap={{ base: '10', lg: '12' }}
                    flex="1"
                >
                    {organisations?.map((organisation) => (
                        <HStack>



                            <VStack>

                                <HStack>
                                    <Avatar src={organisation?.logo?.url} boxSize="16" />
                                    <Stack spacing="3">
                                        <Link href={`/organisation/${organisation?.id}`}> <Text fontSize="md" fontWeight="semibold" >{organisation?.nom}</Text></Link>
                                        {/* <Text fontSize="sm" fontWeight="semibold" color="brand.jaune.900">
                                            {organisation?.pays?.nom}
                                        </Text> */}


                                        {/* <Text color="muted" textAlign={"justify"} noOfLines={3}>{Parser(organisation?.description)}</Text> */}
                                    </Stack>
                                </HStack>
                                <Center>
                                    <UserInfo elements={[

                                        {
                                            icon: UilFacebook,
                                            label: organisation?.facebook ? 'Facebook' : '',
                                            lien: organisation?.facebook ? organisation?.facebook : '',
                                        },
                                        {
                                            icon: UilTwitter,
                                            label: organisation?.twitter ? 'Twitter' : '',
                                            lien: organisation?.twitter ? organisation?.twitter : '',
                                        },
                                        {
                                            icon: UilGlobe,
                                            label: organisation?.site_web ? 'Site web' : '',
                                            lien: organisation?.site_web ? organisation?.site_web : '',
                                        },
                                        {
                                            icon: UilLinkedin,
                                            label: organisation?.linkdin ? 'Linkedin' : '',
                                            lien: organisation?.linkdin ? organisation?.linkdin : '',
                                        }
                                        , {
                                            icon: UilMap,
                                            label: organisation?.adresse || organisation?.province ? `${organisation?.adresse} ${organisation?.province} ${organisation?.pays?.nom}` : '',
                                            lien: '',
                                        },

                                    ]} />
                                </Center>


                            </VStack>
                        </HStack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
    </Box>
)