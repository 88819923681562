import axios from 'axios';

const API_PATH = "https://api.zoa-plateforme.org/api"
// const API_PATH = "http://localhost:5000/api"
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export default class APIClient {

    constructor(token, logout) {
        this.client = axios.create({
            baseURL: API_PATH,
            timeout: 300000,
            mode: "cors",
            headers: {
                'X-CSRF-TOKEN': readCookie('csrftoken'),
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            'withCredentials': true,
            'crossOrigin': true,

        });
        this.logout = logout
    }

    static instance(token, logout) {
        if ((!APIClient.hasOwnProperty('INSTANCE')) || (!APIClient.INSTANCE instanceof APIClient))
            APIClient.INSTANCE = new APIClient(token, logout);
        return APIClient.INSTANCE;
    }

    payload(loading, dismissible, message, autoDismiss, color, action) {
        return {
            loading: loading,
            dismissible: dismissible,
            message: message,
            autoDismiss: autoDismiss,
            color: color,
            action: action
        };
    }

    async post(token = null, url, data, successCallback, errorCallback) {
        this.client.post(url, JSON.stringify(data), {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',

            }
        })
            .then(response => {
                if ([200, 201, 204].includes(response.status)) {
                    successCallback(response);
                } else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }
    async postFile(token = null, url, data, successCallback, errorCallback) {
        this.client.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if ([200, 201, 204].includes(response.status)) {
                    successCallback(response);
                }
                else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }

    async put(token = null, url, data, successCallback, errorCallback) {
        this.client.put(url, data, {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if ([200, 201, 204].includes(response.status) ) {
                    successCallback(response);
                }
                else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }

    async get(token = null, url, successCallback, errorCallback) {
        this.client.get(url, {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {

                if ([200, 201, 204].includes(response.status)) {
                    successCallback(response);
                } else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log("errorr", e);
                if (errorCallback != null) errorCallback(e);
            });
    }

    async delete(token = null, url, successCallback, errorCallback) {
        this.client.delete(url, {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {

                if ([200, 201, 204].includes(response.status)) {
                    successCallback(response);
                } if (response.status === 401) {
                    this.logout()
                } else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }
}

